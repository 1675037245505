<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== MAIN ========== -->
    <!-- Hero Section -->
    <div class="bg-primary bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/hk-bg-light-2.jpg') + ')'}">
      <div class="container space-bottom-md-2 space-top-1">
        <div class="w-lg-65 text-center mx-lg-auto">
          <div>
            <img class="img-fluid mb-8" src="@/assets/img/apidays-2023/images/apidays-2023-logo.png" style="max-width:13rem">
          </div>
          <h1 class="space-bottom-2">{{ sponsorData.company_name }}</h1>
        </div>
      </div>
    </div>
    <!-- End Hero Section -->

    <!-- Content Section -->
    <div class="container space-md-2 space-lg-0">
      <div class="row">
        <div id="stickyBlockStartPoint" class="col-lg-4 mt-lg-n11 mb-7 mb-lg-0">
          <!-- Sidebar Content -->
          <div
            class="js-sticky-block shadow-sm"
            data-hs-sticky-block-options="{
              &quot;parentSelector&quot;: &quot;#stickyBlockStartPoint&quot;,
              &quot;breakpoint&quot;: &quot;lg&quot;,
              &quot;startPoint&quot;: &quot;#stickyBlockStartPoint&quot;,
              &quot;endPoint&quot;: &quot;#stickyBlockEndPoint&quot;,
              &quot;stickyOffsetTop&quot;: 20,
              &quot;stickyOffsetBottom&quot;: 12
            }"
          >
            <div class="card-header text-center py-5">
              <div class="max-w-27rem mx-auto">
                <img class="img-fluid" :src="require(`@/assets/img/apidays-2023/organizers/${sponsorName}.png`)">
              </div>
            </div>

            <div class="card-body">
              <div class="pb-2">
                <div v-if="sponsorData.popup_website" class="font-size-1 mb-4">
                  <h6 class="mb-1">Website</h6>
                  <a :href="sponsorData.popup_website" class="break-word" target="_blank">{{ sponsorData.popup_website }}</a>
                </div>
                <div v-if="sponsorData.popup_email" class="font-size-1 mb-4">
                  <h6 class="mb-1">Contact Email</h6>
                  <a :href="`mailto:${sponsorData.popup_email}`">{{ sponsorData.popup_email }}</a>
                </div>
                <div v-if="sponsorData.social_media && (sponsorData.social_media.fb || sponsorData.social_media.ll)" class="font-size-1 mb-4">
                  <h6 class="mb-2">Social Media</h6>
                  <ul class="list-inline mb-0">
                    <li v-if="sponsorData.social_media.fb" class="list-inline-item">
                      <a class="btn btn-xs btn-icon btn-soft-primary" :href="sponsorData.social_media.fb" target="_blank">
                        <i class="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li v-if="sponsorData.social_media.ll" class="list-inline-item">
                      <a class="btn btn-xs btn-icon btn-soft-primary" :href="sponsorData.social_media.ll" target="_blank">
                        <i class="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- End Sidebar Content -->
        </div>

        <div class="col-lg-8 space-lg-2">
          <div v-if="sponsorData.description" class="pl-lg-4 mb-10">
            <h3 class="mb-3">Company Description</h3>
            <p v-html="sponsorData.description" />
          </div>
          <div v-if="sponsorData.talk && sponsorData.talk.length > 0" class="pl-lg-4 mb-10">
            <h3 class="mb-3">Day 2 Featured Talk</h3>
            <div v-for="(talk, index) in sponsorData.talk" :key="talk.talk_title" class="r" :class="{'mb-4': index < sponsorData.talk.length}">
              <div class="">
                <a class="card h-100 shadow-sm">
                  <div class="card-body">
                    <p class="text-body">{{ talk.location }} |  31 AUG, {{ talk.start_time }} - {{ talk.end_time }}</p>
                    <h4 class="mb-3">{{ talk.talk_title }}</h4>
                    <div v-for="speaker in talk.speakers" :key="speaker.name" class="media align-items-center border-top border-bottom py-3">
                      <div class="avatar avatar-circle avatar-lg">
                        <img class="avatar-img" :src="speaker.icon ? require(`@/assets/img/apidays-2023/speakers/${speaker.icon}`) : require('@/assets/img/apidays-2023/speakers/img12.jpg')">
                      </div>
                      <div class="media-body ml-3">
                        <h5 class="mb-0">{{ speaker.name }}</h5>
                        <p class="mb-0">{{ speaker.title }} at {{ talk.company_name }}</p>
                      </div>
                    </div>

                  </div>
                </a>
              </div>
            </div>
          </div>
          <div v-if="sponsorData.booth_video_type && sponsorData.booth_video" class="pl-lg-4 mb-10">
            <h3 class="mb-3">Company Video / Slide</h3>
            <div class="">
              <div class="">
                <div v-if="sponsorData.booth_video_type !== 'yt'" id="VideoPlayer" class="video-player" style="background-color:#f7faff">
                  <img class="img-fluid video-player-preview" :src="require(`@/assets/img/apidays-2023/thumbnails/${sponsorData.booth_video_thumbnail}`)">
                  <a
                    class="js-inline-video-player video-player-btn video-player-centered"
                    href="javascript:;"
                    :data-hs-video-player-options="`{
                    &quot;videoType&quot;: &quot;vimeo&quot;,
                    &quot;videoId&quot;: &quot;${sponsorData.booth_video}&quot;,
                    &quot;parentSelector&quot;: &quot;#VideoPlayer&quot;,
                    &quot;targetSelector&quot;: &quot;#VideoIframe&quot;,
                    &quot;isAutoplay&quot;: true,
                    &quot;isMuted&quot;: false,
                    &quot;classMap&quot;: {
                    &quot;toggle&quot;: &quot;video-player-played&quot;
                    }
                    }`"
                  >
                    <span id="ga-event-apidays-2022-opening-video-play-button" class="video-player-icon video-player-icon-primary">
                      <i class="fas fa-play" />
                    </span>
                  </a>
                  <div class="embed-responsive embed-responsive-16by9">
                    <div id="VideoIframe" />
                  </div>
                </div>
                <div v-else id="youTubeVideoPlayer1" class="video-player bg-dark mb-4">

                  <img class="img-fluid video-player-preview" :src="require(`@/assets/img/apidays-2023/thumbnails/${sponsorData.booth_video_thumbnail}`)">
                  <a
                    class="js-fancybox video-player-btn video-player-centered"
                    href="javascript:;"
                    :data-hs-fancybox-options="`{
                        &quot;src&quot;: &quot;//www.youtube.com/watch?v=${sponsorData.booth_video}uo&quot;,
                        &quot;speed&quot;: 700,
                        &quot;buttons&quot;: [&quot;fullScreen&quot;, &quot;close&quot;],
                        &quot;vimeo&quot;: {
                          &quot;autoplay&quot;: 1
                        }
                      }`"
                  >
                    <span class="video-player-icon">
                      <i class="fas fa-play" />
                    </span>
                  </a>
                  <div class="embed-responsive embed-responsive-16by9">
                    <div id="youTubeVideoIframe1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="sponsorData.external_links && sponsorData.external_links.length > 0" class="pl-lg-4 mb-10">
            <h3 class="mb-2">External Links</h3>
            <a v-for="links in sponsorData.external_links" :key="links.link" target="_blank" class="d-block" :href="links.link">{{ links.linkText }}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- End Content Section -->

    <!-- Sticky Block End Point -->
    <div id="stickyBlockEndPoint" />
  </main>
  <!-- ========== END MAIN ========== -->

</template>

<script>
const $ = require('jquery')
// import OrganiserBar from '@/components/organiser-bar'
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import '@/assets/vendor/slick-carousel/slick/slick.js'
import HSStickyBlock from '@/assets/vendor/hs-sticky-block/src/js/hs-sticky-block'
import HSVideoPlayer from '@/assets/vendor/hs-video-player/src/js/hs-video-player'

import 'bootstrap'
import SponsorData from './sponsorData.json'

import '@/assets/js/hs.core.js'
import '@/assets/js/hs.fancybox.js'
import '@/assets/vendor/fancybox/dist/jquery.fancybox.js'

export default {
  name: 'Apidays',
  // components: {
  //   OrganiserBar
  // },
  data() {
    return {
      feature_list: [
        {
          icon: 'fas fa-home',
          content: 'Value Business Assets'
        },
        {
          icon: 'fas fa-key',
          content: 'Open API'
        },
        {
          icon: 'fas fa-lightbulb',
          content: 'Developer'
        },
        {
          icon: 'fas fa-mobile-alt',
          content: 'Innovative Solutions'
        },
        {
          icon: 'fas fa-thumbs-up',
          content: 'Delightful Customer & Employee Experience'
        }
      ],
      organiser_list: {
        title: 'API EcoBooster',
        image_path: 'apiecobooster',
        css: '',
        organiser: [
          {
            post: 'Organiser',
            image_url: 'hkstp.png',
            data_aos_delay: 0,
            css: ''
          },
          {
            post: 'Lead Partner',
            image_url: 'hsbc.png',
            data_aos_delay: 150,
            css: 'column-divider-md column-divider-20deg px-md-3'
          },
          {
            post: 'Technology Partner',
            image_url: 'benovelty.png',
            data_aos_delay: 200,
            css: 'column-divider-md column-divider-20deg px-md-3'
          }
        ]
      },
      section_list: [
        {
          image_order: 'order-lg-1',
          image_url: 'payment-1.jpg',
          content_order: 'order-lg-2',
          title: 'How it works',
          header: 'Smart Banking',
          discription: 'Echo with the Open API Framework initiated by local regulator to extend banking footprints to broader use cases:',
          list: [
            'Loan Services',
            'Transactions and Operation',
            'Credit Card',
            'Digital Payment',
            'Customer Record'
          ]
        },
        {
          image_order: 'order-lg-2',
          image_url: 'coding-2.jpg',
          content_order: 'order-lg-1',
          title: 'Why join',
          header: 'Call For Developers',
          discription: 'For qualified developer, you will be offered:',
          list: [
            'HSBC Mentorship Sessions',
            'Technical Clinics',
            'Sandbox for Solution Testing',
            'Exposure to Users of Banking Industry'
          ]
        }
      ],
      timeline_list: [
        {
          date: '14 Aug 2020',
          event: 'Application Deadline'
        },
        {
          date: '4 Sep 2020',
          event: 'Announcement of Shortlisted Proposals'
        },
        {
          date: 'Sep–Oct 2020',
          event: 'Training and Technical Clinics'
        },
        {
          date: 'Oct 2020',
          event: 'Demonstration Day'
        }
      ],
      sponsorName: '',
      sponsorData: {}
    }
  },
  computed: {
  },
  mounted() {
    this.sponsorName = this.$route.path.split('/')[2]
    console.log(this.sponsorName)
    this.sponsorData = SponsorData[this.sponsorName]

    // this.loadScript('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //         portalId: "7091000",
    //         formId: "4daac438-a701-4a30-89de-fefdd7a7306f"
    //     });
    //   `
    //   this.$refs.myform.appendChild(secondScript)
    // })
    this.$nextTick(() => {
      $('.js-go-to').each(function() {
        new HSGoTo($(this)).init()
      })
      $('.js-sticky-block').each(function() {
        new HSStickyBlock($(this)).init()
      })
      $('.js-inline-video-player').each(function() {
        new HSVideoPlayer($(this)).init()
      })
      $('.js-fancybox').each(function() {
        $.HSCore.components.HSFancyBox.init($(this))
      })
    })
  },
  // methods: {
  //   loadScript(url, callback) {
  //     var script = document.createElement('script')
  //     script.type = 'text/javascript'
  //     script.src = url
  //     script.onreadystatechange = callback
  //     script.onload = callback
  //     this.$refs.myform.appendChild(script)
  //   }
  // },
  metaInfo() {
    return {
      title: 'Apidays Connect Hong Kong 2023',
      titleTemplate: `${this.sponsorData.company_name} | %s`,
      link: [
        { rel: 'canonical', href: `https://www.apidays.hk/2023/${this.sponsorName}` }
      ],
      meta: [
        { name: 'description', content: `${this.sponsorData.company_name} | Apidays Connect Hong Kong 2023` },
        { property: 'og:site_name', content: `${this.sponsorData.company_name} | Apidays Connect Hong Kong 2023` },
        { property: 'og:title', content: 'Apidays Connect Hong Kong 2023' },
        { property: 'og:description', content: '30-31 Aug, 2023 - The API Landscape of Hong Kong | apidays Connect Hong Kong 2023 is going to bring together leading API experts both local and international to share their seasonal insights and lessons learnt in driving the team and business under the “new normal”.' },
        { property: 'og:image', content: 'https://www.apidays.hk/assets/img/thumbnails/apidays-hongkong-2023.jpg' },
        { property: 'og:url', content: 'https://www.apidays.hk/' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../../assets/vendor/slick-carousel/slick/slick.css");
@import url("../../../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");
.break-word {
  word-break: break-word;
}
</style>
